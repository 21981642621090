var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/Base.jinja"] = require( "front/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/Button/Button.jinja"] = require( "front/components/Button/Button.jinja" );
dependencies["front/components/TopNav/TopNav.jinja"] = require( "front/components/TopNav/TopNav.jinja" );
dependencies["front/components/TagList/TagList.jinja"] = require( "front/components/TagList/TagList.jinja" );
dependencies["front/components/SectionBadge/SectionBadge.jinja"] = require( "front/components/SectionBadge/SectionBadge.jinja" );
dependencies["front/components/PostGrid/PostGrid.jinja"] = require( "front/components/PostGrid/PostGrid.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/MediaSubscribe/MediaSubscribe.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/Base.jinja", true, "assets/app/front/pages/MediaSubscribe/MediaSubscribe.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/MediaSubscribe/MediaSubscribe.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "svgSprite")) {
var t_7 = t_4.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_7);
output += "\n";
env.getTemplate("front/components/Button/Button.jinja", false, "assets/app/front/pages/MediaSubscribe/MediaSubscribe.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "Button")) {
var t_11 = t_8.Button;
} else {
cb(new Error("cannot import 'Button'")); return;
}
context.setVariable("Button", t_11);
output += "\n";
env.getTemplate("front/components/TopNav/TopNav.jinja", false, "assets/app/front/pages/MediaSubscribe/MediaSubscribe.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "TopNav")) {
var t_15 = t_12.TopNav;
} else {
cb(new Error("cannot import 'TopNav'")); return;
}
context.setVariable("TopNav", t_15);
output += "\n";
env.getTemplate("front/components/TagList/TagList.jinja", false, "assets/app/front/pages/MediaSubscribe/MediaSubscribe.jinja", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
if(Object.prototype.hasOwnProperty.call(t_16, "TagList")) {
var t_19 = t_16.TagList;
} else {
cb(new Error("cannot import 'TagList'")); return;
}
context.setVariable("TagList", t_19);
output += "\n";
env.getTemplate("front/components/SectionBadge/SectionBadge.jinja", false, "assets/app/front/pages/MediaSubscribe/MediaSubscribe.jinja", false, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
t_20.getExported(function(t_22,t_20) {
if(t_22) { cb(t_22); return; }
if(Object.prototype.hasOwnProperty.call(t_20, "SectionBadge")) {
var t_23 = t_20.SectionBadge;
} else {
cb(new Error("cannot import 'SectionBadge'")); return;
}
context.setVariable("SectionBadge", t_23);
output += "\n";
env.getTemplate("front/components/PostGrid/PostGrid.jinja", false, "assets/app/front/pages/MediaSubscribe/MediaSubscribe.jinja", false, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
t_24.getExported(function(t_26,t_24) {
if(t_26) { cb(t_26); return; }
if(Object.prototype.hasOwnProperty.call(t_24, "PostGrid")) {
var t_27 = t_24.PostGrid;
} else {
cb(new Error("cannot import 'PostGrid'")); return;
}
context.setVariable("PostGrid", t_27);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_29,t_28) {
if(t_29) { cb(t_29); return; }
output += t_28;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_31,t_30) {
if(t_31) { cb(t_31); return; }
output += t_30;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_33,t_32) {
if(t_33) { cb(t_33); return; }
output += t_32;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_35,t_34) {
if(t_35) { cb(t_35); return; }
output += t_34;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_37,t_36) {
if(t_37) { cb(t_37); return; }
output += t_36;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 9;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 9, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Медиа"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 10;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 10, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Медиа"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 12;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    ";
output += runtime.suppressValue((lineno = 13, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed TopNav--posts","currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path")})])), env.opts.autoescape);
output += "\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 16;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "PostListPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 18;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"PostList\">\n        <div class=\"PostList-sectionHeader\" data-animation=\"1\" data-animation-type=\"fade\" data-animation-state=\"triggered\" data-animation-delay=\"0\">\n            <h1 class=\"PostList-header\">";
output += runtime.suppressValue((lineno = 21, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Медиа"])), env.opts.autoescape);
output += "</h1>\n\n            <div class=\"PostList-sectionList\">\n                ";
frame = frame.push();
var t_40 = runtime.contextOrFrameLookup(context, frame, "sections");
if(t_40) {t_40 = runtime.fromIterator(t_40);
var t_39 = t_40.length;
for(var t_38=0; t_38 < t_40.length; t_38++) {
var t_41 = t_40[t_38];
frame.set("s", t_41);
frame.set("loop.index", t_38 + 1);
frame.set("loop.index0", t_38);
frame.set("loop.revindex", t_39 - t_38);
frame.set("loop.revindex0", t_39 - t_38 - 1);
frame.set("loop.first", t_38 === 0);
frame.set("loop.last", t_38 === t_39 - 1);
frame.set("loop.length", t_39);
output += "\n                    ";
if(runtime.memberLookup((t_41),"slug") != "podcasts") {
output += "\n                    <a\n                        href=\"";
output += runtime.suppressValue((lineno = 27, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["posts:section",runtime.makeKeywordArgs({"args": [runtime.memberLookup((t_41),"slug")]})])), env.opts.autoescape);
output += "\"\n                        data-section=\"";
output += runtime.suppressValue(runtime.memberLookup((t_41),"slug"), env.opts.autoescape);
output += "\"\n                        class=\"PostList-sectionItem ";
output += runtime.suppressValue((runtime.memberLookup((t_41),"slug") == runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "section")),"slug")?"isActive":""), env.opts.autoescape);
output += "\"\n                    >\n                        ";
output += runtime.suppressValue(runtime.memberLookup((t_41),"title"), env.opts.autoescape);
output += "\n                    </a>\n                    ";
;
}
output += "\n                ";
;
}
}
frame = frame.pop();
output += "\n                <a href=\"";
output += runtime.suppressValue((lineno = 35, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["posts:subscribe"])), env.opts.autoescape);
output += "\" class=\"PostList-sectionItem isActive\">Наша рассылка</a>\n            </div>\n\n            <div class=\"PostList-socialList\">\n                <a class=\"PostList-socialItem PostList-socialItem--telegram\" href=\"https://t.me/mosbrew\" target=\"_blank\" rel=\"noopener noreferrer nofollow\">";
output += runtime.suppressValue((lineno = 39, colno = 168, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["tg"])), env.opts.autoescape);
output += "</a>\n                <a class=\"PostList-socialItem PostList-socialItem--vk\" href=\"https://vk.com/territorija_mosbrew\" target=\"_blank\" rel=\"noopener noreferrer nofollow\">";
output += runtime.suppressValue((lineno = 40, colno = 176, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["vk"])), env.opts.autoescape);
output += "</a>\n                <a class=\"PostList-socialItem PostList-socialItem--dzen\" href=\"https://dzen.ru/mosbrew\" target=\"_blank\" rel=\"noopener noreferrer nofollow\">";
output += runtime.suppressValue((lineno = 41, colno = 167, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["dzen-white"])), env.opts.autoescape);
output += "</a>\n            </div>\n        </div>\n\n        <div class=\"PostList-main\">\n            <div class=\"MediaSubscribe\">\n                <div class=\"MediaSubscribe-content\">\n                    <h1 class=\"MediaSubscribe-title\">Подпишитесь на&nbsp;нашу рассылку</h1>\n                    <p class=\"MediaSubscribe-text\">Раз в&nbsp;месяц мы&nbsp;будем присылать вам письмо о&nbsp;главных событиях с&nbsp;приятными бонусами. Вы&nbsp;всегда сможете отписаться от&nbsp;рассылки.</p>\n\n                    <form class=\"MediaSubscribe-form\" action=\"/api/subscribe/\" method=\"post\">\n                        <div class=\"MediaSubscribe-fieldGroup\">\n                            <input id=\"mediaSubscribeEmail\" class=\"MediaSubscribe-field\" type=\"email\" name=\"email\" placeholder=\"Email\">\n                            <button class=\"Button MediaSubscribe-btn\" type=\"submit\" value=\"\" name=\"subscribe\">Отправить</button>\n                        </div>\n\n                        <div class=\"MediaSubscribe-message MediaSubscribe-message--error\">";
output += runtime.suppressValue((lineno = 57, colno = 94, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Адрес введен неверно"])), env.opts.autoescape);
output += "</div>\n                        <div class=\"MediaSubscribe-message MediaSubscribe-message--thanks\">";
output += runtime.suppressValue((lineno = 58, colno = 95, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Спасибо, что подписались!"])), env.opts.autoescape);
output += "</div>\n                    </form>\n                </div>\n            </div>\n        </div>\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_topNav: b_topNav,
b_body_class: b_body_class,
b_content: b_content,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/MediaSubscribe/MediaSubscribe.jinja"] , dependencies)