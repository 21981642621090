var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/components/ButtonIcon/ButtonIcon.jinja"] = require( "front/components/ButtonIcon/ButtonIcon.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/Notify/Notify.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/components/ButtonIcon/ButtonIcon.jinja", false, "assets/app/front/components/Notify/Notify.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "ButtonIcon")) {
var t_4 = t_1.ButtonIcon;
} else {
cb(new Error("cannot import 'ButtonIcon'")); return;
}
context.setVariable("ButtonIcon", t_4);
output += "\n\n";
var macro_t_5 = runtime.makeMacro(
["notify"], 
["classes"], 
function (l_notify, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("notify", l_notify);
frame.set("classes", Object.prototype.hasOwnProperty.call(kwargs, "classes") ? kwargs["classes"] : "");var t_6 = "";t_6 += "\n    <div class=\"Notify ";
t_6 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classes"), env.opts.autoescape);
t_6 += "\">\n        <div class=\"Notify-img\">\n            <img src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_notify),"img")),"w250"), env.opts.autoescape);
t_6 += "\" srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_notify),"img")),"w500"), env.opts.autoescape);
t_6 += " 2x\" alt=\"";
t_6 += runtime.suppressValue(env.getFilter("striptags").call(context, runtime.memberLookup((l_notify),"title")), env.opts.autoescape);
t_6 += "\">\n        </div>\n\n        <div class=\"Notify-text\">\n            <div>\n                ";
t_6 += runtime.suppressValue(runtime.memberLookup((l_notify),"title"), env.opts.autoescape);
t_6 += " <a target=\"_blank\" href=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((l_notify),"link"), env.opts.autoescape);
t_6 += "\">";
t_6 += runtime.suppressValue(runtime.memberLookup((l_notify),"linkTitle"), env.opts.autoescape);
t_6 += "</a>\n            </div>\n        </div>\n        ";
t_6 += "\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_6);
});
context.addExport("Notify");
context.setVariable("Notify", macro_t_5);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "render") == true) {
output += "\n    ";
output += runtime.suppressValue((lineno = 22, colno = 13, runtime.callWrap(macro_t_5, "Notify", context, [runtime.contextOrFrameLookup(context, frame, "notify"),runtime.makeKeywordArgs({"classes": runtime.contextOrFrameLookup(context, frame, "classes")})])), env.opts.autoescape);
output += "\n";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/Notify/Notify.jinja"] , dependencies)